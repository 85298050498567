@use '../../mixin' as m;
/*----------
	remarks
----------*/
$_root: '.c-remarks';

.c-remarks {
	font-size: 1.2rem;
	line-height: var(--line-height-l);
	color: var(--color-txt);

	&__item {
		padding-left: 1.5em;
		text-indent: -.75em;

		&::before {
			content: '※';
			display: inline-block;
			margin-right: .5em;
		}
	}

	&__item + &__item {
		margin-top: 10px;
	}

	&--order {
		counter-reset: orderlist;

		#{$_root}__item {
			padding-left: 3em;
			text-indent: -3em;

			&::before {
				counter-increment: orderlist;
				content: '※'counter(orderlist);
				min-width: 2em;
				margin-right: 1em;
				text-align: right;
			}
		}
	}

	@include m.mq-pc {
		font-size: 1.4rem;
	}
}
