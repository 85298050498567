@use '../../mixin' as m;
/*----------
	remarks
----------*/

.c-qalist {

	&__header {
		display: flex;

		&-icon {
			min-width: 40px;
			padding: 5px;
			height: 40px;
			background: var(--color-txt-caption);
			color: #FFF;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-right: 20px;
			font-size: 2rem;
			font-weight: bold;
		}

		&-text {
			margin-top: 7px;
			flex: 1;
		}
	}

	&__body {
		display: flex;
		margin-top: 16px;

		&-icon {
			min-width: 40px;
			padding: 5px;
			height: 40px;
			background: var(--color-txt-caption);
			color: #FFF;
			display: flex;
			align-items: center;
			justify-content: center;
			flex-direction: column;
			margin-right: 20px;
			font-size: 2rem;
			font-weight: bold;
		}

		&-text {
			margin-top: 2px;
			flex: 1;
		}
	}

}
