@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-accordion
----------*/
$_root: '.c-accordion';

.c-accordion {
	border-bottom: 1px solid var(--color-border);

	&__item {
		position: relative;
		margin-top: -1px;

		&--parent {
			border-top: 1px solid var(--color-border);
			cursor: pointer;

			&::after {
				@include m.iconfont-default;
				content: var(--icon-plus);
				font-size: 1.5rem;
				position: absolute;
				top: 50%;
				right: 14px;
				transform: translateY(-50%);
				pointer-events: none;
			}

			&.is-active {
				background: #fff;

				&::after {
					content: var(--icon-minus);
				}
			}
		}

		&--child {
			// display: none;
			height: 0;
			position: relative;
			overflow: hidden;
			opacity: 0;
			pointer-events: none;
			transition: all .4s linear;

			&.is-active {
				background: #fff;
				height: auto;
				opacity: 1;
				pointer-events: all;
			}
		}

		&-inner {
			font-size: 1.6rem;
			line-height: var(--line-height-m);

			&--parent {
				font-weight: 500;
				font-size: 1.5rem;
				line-height: var(--line-height-s);
				padding: 21px 55px 21px 20px;
			}

			&--child {
				margin: 18px 25px 40px 20px;
				font-size: 1.4rem;
				padding-left: 20px;
				border-left: 1px solid var(--color-txt);

				&::before {
					padding-right: 11px;
					color: var(--color-txt);
				}
			}
		}
	}

	& + & {
		margin-top: -1px;
	}

	@include m.mq-pc {
		&__item {
			&--parent {
				&::after {
					font-size: 1.8rem;
					right: 16px;
					transition: color .2s;
				}

				&:hover {
					&::after {
						color: var(--color-prim);
					}
				}
			}

			&-inner {
				&--parent {
					padding: 30px 50px;
					font-size: 1.8rem;
					transition: color .2s;

					&::before {
						padding-right: 28px;
					}

					&:hover {
						color: var(--color-prim);
					}
				}

				&--child {
					margin: 15px 150px 60px 60px;
					padding-left: 40px;
					font-size: 1.6rem;

					&::before {
						padding-right: 19px;
					}
				}
			}
		}
	}

	// 単体
	&-v2 {
		#{$_root}__item {
			&--parent {
				background-color: #fff;
				border: 1px solid var(--color-border);

				&::after {
					right: 25px;
				}
			}

			&--child {
				&.is-active {
					border: 1px solid var(--color-border);
					border-top: none;
				}
			}

			&-inner {
				&--parent {
					padding-right: 70px;
				}
			}
		}
	}
}
