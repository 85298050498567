@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	grid
----------*/

.g-grid {
	$_root: '.g-grid';

	margin: 0 auto;

	@include m.mq-sp {
		&__item + &__item {
			margin-top: 20px;
		}

		&--card {
			#{$_root}__item + #{$_root}__item {
				margin-top: 40px;
			}
		}

		&--list {
			#{$_root}__item + #{$_root}__item {
				margin-top: 10px;
			}
		}
	}

	@include m.mq-pc {
		width: 50%;

		&__item + &__item {
			margin-top: 40px;
		}

		@supports (display: grid) {
			display: grid;
			gap: 40px 50px;
			width: auto;

			&--2up {
				grid-template-columns: repeat(2, 1fr);
			}

			&--3up {
				grid-template-columns: repeat(3, 1fr);
			}

			&--4up {
				grid-template-columns: repeat(4, 1fr);
			}

			&__item + &__item {
				margin-top: 0;
			}

			&--list {
				gap: 10px 20px;
			}
		}
	}
}

// ✂️ -- option --
// flex版
// .g-grid {
// 	@include m.mq-pc {
// 		display: flex;
// 		flex-wrap: wrap;
// 		justify-content: flex-start;
// 		align-items: stretch;
// 		margin-left: -40px;
// 		&__item {
// 			flex: 0 1 auto;
// 			margin-left: 40px;
// 			margin-bottom: 40px;
// 		}
// 		&--2up {
// 			.g-grid__item {
// 				flex-basis: calc(50% - 40px);
// 				&:nth-child(2n+1):nth-last-child(-n+2) {
// 					margin-bottom: 0;
// 					& ~ .g-grid__item {
// 						margin-bottom: 0;
// 					}
// 				}
// 			}
// 		}
// 		&--3up {
// 			.g-grid__item {
// 				flex-basis: calc(33.3333% - 40px);
// 				&:nth-child(3n+1):nth-last-child(-n+3) {
// 					margin-bottom: 0;
// 					& ~ .g-grid__item {
// 						margin-bottom: 0;
// 					}
// 				}
// 			}
// 		}
// 		&--4up {
// 			.g-grid__item {
// 				flex-basis: calc(25% - 40px);
// 				&:nth-child(4n+1):nth-last-child(-n+4) {
// 					margin-bottom: 0;
// 					& ~ .g-grid__item {
// 						margin-bottom: 0;
// 					}
// 				}
// 			}
// 		}
// 	}
// }
