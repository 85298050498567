@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	pagination
----------*/
$_root: '.c-pagination';

.c-pagination {
	&__items {
		display: flex;
		align-items: center;
		justify-content: center;
	}

	&__item {
		margin: 0 5px;

		> a {
			display: block;
			color: inherit;
		}
	}

	&__page {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		text-align: center;
		font-size: 1.6rem;
		font-family: var(--font-inter);
		line-height: 1;
		border: 1px solid var(--color-txt);
		border-radius: 50%;
		color: var(--color-txt);
		transition: all .2s ease;

		&--current {
			background: var(--color-prim);
			border: 1px solid var(--color-prim);
			color: #fff;

		}
	}

	&__ellip {
		display: block;
		width: auto;
		font-size: 1.8rem;
		text-align: center;
		font-family: var(--font-inter);
		line-height: 1;
	}

	&__prev,
	&__next {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 36px;
		height: 36px;
		text-align: center;
		font-size: .9rem;
		border: 1px solid var(--color-txt);
		border-radius: 50%;
		color: var(--color-txt);
		transition: all .2s ease;
	}

	@include m.mq-pc {

		#{$_root}__page {
			width: 50px;
			height: 50px;
			font-size: 1.8rem;
			transition: color .2s, border .2s;
		}

		#{$_root}__prev,
		#{$_root}__next {
			width: 50px;
			height: 50px;
			font-size: 1.2rem;
			transition: color .2s, border .2s;
		}

		a:hover {
			#{$_root}__page,
			#{$_root}__prev,
			#{$_root}__next {
				color: var(--color-prim);
				border: 1px solid var(--color-prim);
			}
		}
	}
}
