@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-box
----------*/

.c-box {
	&__inner {
		padding: 20px;
		border: 1px solid var(--color-txt);
		border-radius: 5px;
	}

	&__header {
		margin-bottom: 20px;

		&-title {
			font-size: 1.6rem;
			font-weight: 500;
			color: var(--color-txt);
			line-height: var(--line-height-s);
			padding-left: 33px;
			text-indent: -33px;

			> i {
				text-indent: 0;
				margin-right: 10px;
				font-size: 2.4rem;
				color: var(--color-prim);
				transform: translateY(0.2em);
			}
		}
	}

	@include m.mq-pc {

		&__inner {
			padding: 30px 40px;
		}

		&__header {
			margin-bottom: 30px;

			&-title {
				font-size: 2rem;

				> i {
					transform: translateY(0.1em);
				}
			}
		}
	}
}
