@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	checkpointlist
----------*/

.c-checkpointlist {
	&__item {
		padding-left: 3em;
		text-indent: -3.5em;
		font-size: 1.6rem;
		font-weight: 500;

		&::before {
			@include m.iconfont-default;
			content: var(--icon-check);
			display: inline-block;
			font-size: 2rem;
			font-family: var(--font-inter);
			color: var(--color-prim);
			margin-right: 16px;
			min-width: 2em;
			text-align: right;
			vertical-align: middle;
		}
	}

	@include m.mq-pc {
		&__item {
			padding-left: 3.5em;
			text-indent: -4.5em;
			font-size: 2rem;

			&::before {
				font-size: 2.4rem;
				margin-right: 43px;
			}
		}
	}

	&__item + &__item {
		margin-top: 15px;

		@include m.mq-pc {
			margin-top: 20px;
		}
	}
}
