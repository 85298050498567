@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-tertiary
----------*/

.c-heading-tertiary {
	font-weight: 500;
	font-size: 1.6rem;
	line-height: var(--line-height-s);
	padding: 15px 19px 16px 18px;
	position: relative;
	background-color: var(--color-heading-thin);

	@include m.mq-pc {
		font-size: 2.4rem;
		padding: 17px 30px 19px 27px;
	}

	& > *[id] {
		margin-top: -90px;
		padding-top: 90px;

		@include m.mq-pc {
			margin-top: -100px;
			padding-top: 100px;
		}
	}

	&::before {
		content: '';
		width: 3px;
		height: 100%;
		background-color: var(--color-prim);
		position: absolute;
		left: 0;
		top: 0;

		@include m.mq-pc {
			width: 2px;
		}
	}
}
