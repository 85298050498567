@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	table
----------*/
$_root: '.c-table';

.c-table {
	&__content {
		width: 100%;
		text-align: left;
		border: none;
		font-size: 1.6rem;
		line-height: var(--line-height-m);

		td {
			padding: 28px 24px;
			border: 1px solid var(--color-border);
			vertical-align: middle;

			// &:empty {
			// 	background: none;
			// 	border: none;
			// }
		}
	}

	&__layouter {
		&--for-pc {
			display: none;
		}

		&--for-sp {
			display: table-column-group;
		}
	}

	&__header {
		border: 1px solid var(--color-border);
		vertical-align: middle;
		padding: 28px 10px;
		background-color: var(--color-heading-dark);
		font-weight: 500;
		text-align: center;
		color: #fff;

		// &:empty {
		// 	background: none;
		// }

		&--red {
			background: var(--color-caution);
		}
	}

	&__narrow-header {
		border: 1px solid var(--color-border);
		vertical-align: middle;
		padding: 14px 10px;
		background-color: var(--color-heading-dark);
		font-weight: 500;
		text-align: center;
		color: #fff;

		// &:empty {
		// 	background: none;
		// 	border: none;
		// }

		&--red {
			background: var(--color-caution);
		}
	}

	&__subheader {
		border: 1px solid var(--color-border);
		vertical-align: middle;
		padding: 28px 10px;
		background-color: var(--color-heading-thin);
		font-weight: 500;
		text-align: center;

		// &:empty {
		// 	background: none;
		// 	border: none;
		// }
	}

	&__cell {
		&--red {
			background: #FFF7F7;
		}
	}

	&--scrollable-v2 {
		$gutter: math.div(20, 375);

		&::after {
			content: '';
			display: block;
			margin-top: 12px;
			padding-top: 32px;
			background: url(/assets/images/table_swipe.svg) no-repeat center;
			background-size: contain;
		}

		#{$_root}__inner {
			margin-left: percentage( $gutter * -1 );
			margin-right: percentage( $gutter * -1 );
			padding-left: percentage( $gutter );
			overflow: auto;
		}

		#{$_root}__body {
			padding-bottom: 10px;
			padding-right: percentage( $gutter );
		}

		#{$_root}__content {
			white-space: nowrap;
		}
	}

	&--scrollable-v3 {
		$gutter: math.div(20, 375);

		&::after {
			content: '';
			display: block;
			margin-top: 12px;
			padding-top: 32px;
			background: url(/assets/images/table_swipe.svg) no-repeat center;
			background-size: contain;
		}

		#{$_root}__inner {
			margin-left: percentage( $gutter * -1 );
			margin-right: percentage( $gutter * -1 );
			padding-left: percentage( $gutter );
			overflow: auto;
		}

		#{$_root}__body {
			padding-bottom: 10px;
			padding-right: percentage( $gutter );
		}

		#{$_root}__content {
			white-space: nowrap;
		}
	}

	@include m.mq-sp {
		&--scrollable {
			$gutter: math.div(20, 375);


			&::after {
				content: '';
				display: block;
				margin-top: 12px;
				padding-top: 32px;
				background: url(/assets/images/table_swipe.svg) no-repeat center;
				background-size: contain;
			}

			#{$_root}__inner {
				margin-left: percentage( $gutter * -1 );
				margin-right: percentage( $gutter * -1 );
				padding-left: percentage( $gutter );
				overflow: auto;
			}

			#{$_root}__body {
				width: 1000px;
				padding-bottom: 10px;
				padding-right: percentage( $gutter );
			}
		}

		&--scrollable-v3 {
			#{$_root}__narrow-header,
			#{$_root}__subheader {
				&.is-stuck {
					position: static!important;
				}
			}
		}
	}

	@include m.mq-pc {
		&__layouter {
			&--for-pc {
				display: table-column-group;
			}

			&--for-sp {
				display: none;
			}
		}

		&--scrollable-v2 {
			$gutter: math.div(20, 375);
			#{$_root}__inner {
				margin: 0 auto;
				padding: 0;
			}

			#{$_root}__body {
				padding-bottom: 10px;
				padding-right: percentage( $gutter );
			}

			#{$_root}__content {
				white-space: nowrap;
			}
		}

		&--scrollable-v3 {
			$gutter: math.div(20, 375);
			#{$_root}__inner {
				margin: 0 auto;
				padding: 0;
			}

			#{$_root}__body {
				padding-bottom: 10px;
				padding-right: percentage( $gutter );
			}

			#{$_root}__content {
				white-space: nowrap;
			}

			#{$_root}__narrow-header,
			#{$_root}__subheader {
				&.is-stuck {
					&::before {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						left: -1px;
						width: 1px;
						height: 100%;
						background: var(--color-border);
					}

					&::after {
						content: '';
						display: block;
						position: absolute;
						top: 0;
						right: -1px;
						width: 1px;
						height: 100%;
						background: var(--color-border);
					}
				}
			}
		}
	}
}
