@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	iconset vertical
----------*/

.c-iconset-v {
	&__image {
		width: 110px;
		margin: 0 auto;
		// border-radius: 50%;
		// overflow: hidden;
	}

	&__content {
		margin-top: 24px;
		@include m.margin-trim;
	}

	&__title {
		text-align: center;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: var(--line-height-s);
	}

	&__text {
		margin-top: 16px;
		font-size: 1.6rem;
	}

	&__button {
		margin: 24px auto;
		padding: 0 10px;
	}

	@include m.mq-pc {
		&__button {
			padding: 0;
		}

		&__button-inner {
			max-width: 312px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
