@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	file
----------*/

.c-file {
	display: block;
	font-size: 1.4rem;
	line-height: 1.2;

	@include m.mq-pc {
		display: flex;
		align-items: center;
		font-size: 1.6rem;
	}

	span {
		padding: 8px 40px;
		text-align: center;
		font-size: 1.4rem;
		font-weight: 500;
		line-height: var(--line-height-s);
		color: inherit;

		@include m.mq-pc {
			font-size: 1.6rem;
		}
	}

	label {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 40px;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		border: 1px solid var(--color-black);
		background-color: var(--color-black);
		color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0,0,0,.05);
		border-radius: 27px;
		margin-bottom: 8px;
		cursor: pointer;


		@include m.mq-pc {
			max-width: 368px;
			margin-bottom: 0;
			margin-right: 10px;
			transition: background-color .2s, color .2s, border-color .2s;

			&:hover {
				border-color: var(--color-prim);
				background-color: var(--color-prim);
				color: #fff;
			}
		}
	}

	input[type='file'] {
		position: absolute;
		clip: rect(0 0 0 0);
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		border: 0;
	}
}

