@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	icontext
----------*/

.c-icontext {
	padding-left: 27px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
	text-indent: -27px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */

	> a {
		color: var(--color-txt);
		font-weight: 500;
	}

	i {
		&[class^='icon-'] {
			text-indent: 0;
			margin-right: 12px;
			font-size: 1.6rem;
			color: var(--color-prim);
			transform: translateY(.1em);
		}

		&[class^='icon-pdf'] {
			color: var(--color-caution);
		}
	}

	@include m.mq-pc {
		> a {
			transition: color .2s;

			&:hover {
				color: var(--color-txt-link);
				text-decoration: underline;
			}
		}
	}
}
