@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	iconset
----------*/

.c-iconset {
	// &__inner {
	// 	display: flex;
	// 	justify-content: flex-start;
	// 	align-items: flex-start;
	// 	flex-wrap: wrap;
	// }

	&__image {
		width: 80px;
		margin-right: 24px;
		// border-radius: 50%;
		// overflow: hidden;
	}

	&__content {
		// flex: 1 0 calc(100% - 104px);
		@include m.margin-trim;
	}

	&__title {
		font-weight: bold;
		font-size: 1.8rem;
		line-height: var(--line-height-s);
	}

	&__text {
		margin-top: 8px;
		font-size: 1.6rem;
	}

	&__button {
		margin-top: 16px;
	}

	@include m.mq-sp {
		&__inner {
			display: grid;
			grid-template-columns: auto 1fr;
			grid-template-rows: auto auto;
		}

		&__image {
			grid-column: 1;
			grid-row: 1/3;
		}

		&__content {
			grid-column: 2;
			grid-row: 1;
		}

		&__button {
			grid-column: 2;
			grid-row: 2;
		}
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			justify-content: flex-start;
			align-items: flex-start;
			flex-wrap: wrap;
		}

		&__image {
			flex: 0 0 80px;
		}

		&__content {
			flex: 1;
		}

		&__button {
			width: 100%;
			margin-top: 40px;
			margin-left: auto;
			margin-right: auto;
		}

		&__button-inner {
			max-width: 312px;
			margin-left: auto;
			margin-right: auto;
		}
	}
}
