@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	search
----------*/

.c-search {
	border-bottom: 1px solid #4A4A4A;
	background: #fff;

	&__body {
		position: relative;
	}

	&__text {
		display: block;
		width: 100%;
		padding: 18px 60px 18px 16px;
		font-size: 1.6rem;
		line-height: 1.6;
		border-radius: 0;
		appearance: none;

		&:focus {
			outline: none;
		}
	}

	&__submit {
		position: absolute;
		top: 0;
		right: 0;
		width: 60px;
		height: 60px;
		font-size: 2.4rem;
		line-height: 60px;
		cursor: pointer;
		color: var(--color-txt);

		> span {
			font-size: 0;
		}
	}
}
