@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	checkbox
----------*/

.c-checkbox {
	/* stylelint-disable no-descending-specificity */
	cursor: pointer;

	input[type='checkbox'] {
		position: absolute;
		clip: rect(0 0 0 0);
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		border: 0;

		&:checked + span {
			color: var(--color-txt);

			&::before {
				background-image: url(/assets/images/marker_checkbox_on.svg);
			}
		}
	}

	span {
		position: relative;
		padding-left: 32px;
		display: inline-block;
		line-height: 2rem;
		text-align: left;

		&::before {
			content: '';
			display: block;
			position: absolute;
			top: 1px;
			left: 0;
			width: 20px;
			height: 20px;
			margin-right: 8px;
			background-image: url(/assets/images/marker_checkbox.svg);
			background-size: contain;
			background-color: #fff;
		}

		a {
			text-decoration: underline;
		}
	}

	&.is-error {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_checkbox_error.svg);
			}
		}
	}

	@at-root .is-error & {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_checkbox_error.svg);
			}
		}
	}

	&.is-agreement-error {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_checkbox_error.svg);
			}
		}
	}

	@at-root .is-agreement-error & {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_checkbox_error.svg);
			}
		}
	}


	@include m.mq-pc {
		span {
			line-height: 2.4rem;

			&::before {
				width: 24px;
				height: 24px;
			}
		}
	}
	/* stylelint-enable no-descending-specificity */
}
