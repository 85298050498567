@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	tag
----------*/

.c-tag {
	display: inline-block;
	vertical-align: middle;

	> a,
	> span {
		display: inline-block;
		min-width: 88px;
		height: 32px;
		padding: 0 11px;
		background-color: rgba(var(--color-prim-rgb), 0.15);
		border: 1px solid transparent;
		border-radius: 5px;
		font-weight: bold;
		font-size: 1.3rem;
		text-align: center;
		white-space: nowrap;
		line-height: 30px;
		color: var(--color-prim);
	}

	> a {
		color: var(--color-txt-link);
	}

	&--border {
		> a,
		> span {
			border-color: var(--color-prim);
			background-color: transparent;
		}
	}

	&--fill {
		> a,
		> span {
			color: #fff;
			border-color: var(--color-prim);
			background-color: var(--color-prim);
		}
	}

	&--red {
		> a,
		> span {
			color: var(--color-caution);
			background-color: rgba(var(--color-caution-rgb), .12);
			border-width: 0;
		}
	}

	&--gray {
		> a,
		> span {
			color: #fff;
			border-color: var(--color-txt-caption);
			background-color: var(--color-txt-caption);
		}
	}

	&--v2 {
		display: inline-block;
		vertical-align: middle;

		> a,
		> span {
			min-width: 81px;
			padding: 8px 15px 9px;
			border: 1px solid var(--color-border);
			border-radius: 16px;
			font-size: 1.3rem;
			line-height: 1;
			font-weight: 500;
			text-align: center;
			background-color: #fff;
			color: var(--color-txt-caption);
		}

		> a {
			border-color: var(--color-txt-link);
			color: var(--color-txt-link);
		}
	}

	&--news {
		> a,
		> span {
			min-width: 140px;
		}
	}

	@include m.mq-pc {
		> a {
			transition: background-color .2s, color .2s;

			&:hover {
				background-color: var(--color-prim);
				color: #fff;
			}
		}

		&--fill {
			/* stylelint-disable no-descending-specificity */
			> a {
				&:hover {
					background-color: #fff;
					color: var(--color-prim);
				}
			}
		}

		&--red {
			> a {
				&:hover {
					background-color: var(--color-caution);
					color: #fff;
				}
			}
		}

		&--gray {
			> a {
				&:hover {
					background-color: #fff;
					color: var(--color-txt-caption);
				}
			}
		}

		&--v2 {
			> a,
			> span {
				min-width: 81px;
			}
		}
	}
}


.c-tag-text {
	display: inline-block;
	padding-left: 30px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
	text-indent: -20px !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */

	> a {
		color: var(--color-txt);
		font-size: 1.6rem;
		font-weight: 500;
		text-decoration: underline;
	}

	i {
		&[class^='icon-'] {
			text-indent: 0;
			margin-right: 10px;
			font-size: 1.8rem;
			color: var(--color-prim);
			transform: translateY(.2em);
		}
	}

	@include m.mq-pc {
		> a {
			transition: color .2s;

			&:hover {
				color: var(--color-txt-link);
				text-decoration: none;
			}
		}
	}
}
