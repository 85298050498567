@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	deflist
----------*/
$_root: '.c-deflist';

.c-deflist {
	&__body {
		border-top: 1px solid var(--color-border);
	}

	&__term {
		padding: 20px 0 8px;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-s);
	}

	&__detail {
		padding: 8px 0 20px;

		p + p {
			margin-top: 20px;
		}
	}

	&__links {
		margin-top: 20px;
	}

	&__link + &__link {
		margin-top: 1em;
	}

	@include m.mq-pc {
		border-bottom: 1px solid var(--color-border);

		&__body {
			display: flex;
			justify-content: space-between;
			align-items: stretch;
		}

		&__term {
			flex: 0 0 percentage( math.div(1, 4) );
			padding: 30px 0;
			font-size: 1.8rem;
		}

		&__detail {
			flex: 1 1 auto;
			margin-left: -1px;
			padding: 30px 0 30px 16px;
		}

		&__links {
			display: flex;
			gap: 20px;
			flex-wrap: wrap;
		}

		&__link + &__link {
			margin-top: 0;
		}
	}
}
