@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textarea
----------*/
.c-textarea {
	> textarea {
		display: block;
		width: 100%;
		height: 300px;
		padding: 14px 15px;
		background-color: #fff;
		border: 1px solid var(--color-border);
		appearance: none;
		-webkit-appearance: none;
		// resize: vertical;

		&::placeholder {
			color: #888888;
		}

		&::-webkit-input-placeholder {
			color: #888888;
		}

		&:-moz-placeholder {
			color: #888888;
		}

		&::-moz-placeholder {
			color: #888888;
		}

		&:-ms-input-placeholder {
			color: #888888;
		}
	}

	&.is-error {

		> textarea {
			color: var(--color-caution);
			border-color: var(--color-caution);
			background: #FFF6F5;
		}
	}
}
