@use '../../mixin' as m;
/*----------
	attentionlist
----------*/

.c-attentionlist {
	font-size: 1.2rem;
	line-height: var(--line-height-l);

	&__item {
		padding-left: 1.5em;
		text-indent: -.75em;
		color: var(--color-caution);

		&::before {
			content: '※';
			display: inline-block;
			margin-right: .5em;
			color: inherit;
		}
	}

	&__item + &__item {
		margin-top: 10px;
	}

	@include m.mq-pc {
		font-size: 1.4rem;
	}
}
