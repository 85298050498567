@use 'sass:math';
@use '../../_mixin' as m;
/*----------s
	personalset
----------*/

.c-personalset {
	$_root: '.c-personalset';

	&__policy {
		height: 500px;
		margin: 0 auto;
		padding: 21px 20px;
		border: 1px solid var(--color-border);
		overflow: auto;
		background: #fff;
		font-size: 1.4rem;
		color: var(--color-txt);
		line-height: var(--line-height-l);
	}

	&__agreement {
		margin: 30px auto 0;

		&-body {
			padding: 10px 16px;
			background: var(--color-bg);
			text-align: center;
		}

		&-check {
			display: table;
			margin: auto;
		}

		&-error {
			opacity: 0;
			height: 0;
			font-size: 1.3rem;
			line-height: 1.3;
			color: var(--color-caution);
			text-align: center;
		}

		&.is-agreement-error {
			#{$_root}__agreement {
				&-error {
					opacity: 1;
					height: auto;
				}
			}
		}
	}

	&__note {
		margin: 20px auto 0;
		line-height: var(--line-height-m);
		color: var(--color-txt-caption);
		text-align: center;
	}

	&__submit {
		margin: 50px auto 0;

		&-inner {
			display: flex;
			width: 100%;
			height: 100%;
			min-height: 50px;
			flex-wrap: nowrap;
			justify-content: center;
			align-items: center;
			position: relative;
			overflow: hidden;
			background-color: var(--color-black);
			color: #fff;
			border-radius: 27px;
			box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);

			&[disabled] {
				opacity: .6;
			}

			&:not([disabled]) {
				cursor: pointer;
			}

			> span {
				padding: 8px 40px;
				text-align: center;
				font-size: 1.4rem;
				font-weight: bold;
				line-height: var(--line-height-s);
				color: inherit;
			}
		}
	}

	.parsley-errors-list {
		font-size: 1.3rem;
		line-height: 1.3;
		color: var(--color-caution);

		&.filled {
			margin-top: 8px;
		}
	}

	@include m.mq-pc {
		&__body {
			margin: 0 auto;
			max-width: 780px;
		}

		&__policy {
			height: 400px;
			padding: 21px 20px;
			font-size: 1.6rem;
		}

		&__agreement {
			max-width: 800px;
			margin: 25px auto 0;

			&-body {
				padding: 25px;
			}

			&-error {
				font-size: 1.5rem;
				line-height: 1.5;
			}
		}

		&__note {
			margin: 80px auto 0;
		}

		&__submit {
			max-width: 367px;
			margin: 55px auto 0;

			&-inner {
				min-height: 54px;
				transition: background-color .2s, color .2s, border-color .2s;

				&:not([disabled]) {
					opacity: 1;

					&:hover {
						background-color: var(--color-prim);
					}
				}
			}
		}

		.parsley-errors-list {
			font-size: 1.5rem;
			line-height: 1.5;
		}
	}
}
