@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	figure
----------*/
$_root: '.c-figure';

.c-figure {
	&__image {
		border-radius: 5px;
		overflow: hidden;
		text-align: center;

		> img {
			max-width: 100%;
			width: auto;
		}
	}

	&__caption {
		margin-top: 15px;
		text-align: left;
		font-size: 1.2rem;
		line-height: var(--line-height-l);
		color: var(--color-txt);
	}

	@include m.mq-pc {
		&__caption {
			font-size: 1.4rem;
		}
	}
}
