@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	feature
----------*/
$_root: '.c-card-feature';

.g-card-feature-container {
	margin: 0 auto;

	@include m.mq-sp {
		&__item + &__item {
			margin-top: 20px;
		}
	}

	@include m.mq-pc {
		width: 50%;

		// &__item {
		// 	+ .g-grid__item {
		// 		margin-top: 40px;
		// 	}
		// }

		@supports (display: grid) {
			display: grid;
			gap: 40px 50px;
			width: auto;

			&--2up {
				grid-template-columns: repeat(2, 1fr);
			}

			&--3up {
				grid-template-columns: repeat(3, 1fr);
			}
		}
	}
}
