@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	image link
----------*/
$_root: '.c-imgset-banner';

.c-imgset-banner {
	&__inner {
		display: flex;
		align-items: center;
		padding: 10px 16px 10px 10px;
		border-radius: 5px;
		position: relative;
		overflow: hidden;
		background-color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);
	}

	&__image {
		flex: 0 0 100px;
		margin-right: 20px;
		overflow: hidden;
		border-radius: 5px;

		> img {
			transition: transform .3s ease-out;
		}
	}

	&__content {
		@include m.margin-trim;
		margin-right: 15px;

		> * + * {
			margin-top: 10px;
		}
	}

	&__subtitle {
		font-size: 1.4rem;
		line-height: var(--line-height-s);
		color: var(--color-txt-caption);
	}

	&__title {
		font-weight: bold;
		font-size: 1.4rem;
		line-height: var(--line-height-s);
		color: var(--color-txt);
		transition: color .2s;
	}

	&__icon {
		width: 18px;
		height: 18px;
		margin-left: auto;
		font-size: 1.8rem;
	}

	@include m.mq-pc {
		&__inner {
			padding: 10px 20px 10px 10px;
		}

		&__image {
			flex: 0 0 80px;
		}

		&__content {
			> * + * {
				margin-top: 0;
			}
		}

		&__subtitle {
			font-size: 1.5rem;
		}

		&__title {
			font-size: 1.8rem;
		}

		&:hover {
			#{$_root}__title {
				color: var(--color-txt-caption);
			}

			#{$_root}__image {
				> img {
					transform: scale(1.05);
				}
			}
		}
	}
}
