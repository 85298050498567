@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	field
----------*/

/* お名前入力用 */
.c-field-name {
	display: flex;
	justify-content: center;

	&__item {
		flex: 1;
	}

	&__item + &__item {
		margin-left: 10px;
	}

	@include m.mq-pc {
		&__item + &__item {
			margin-left: 50px;
		}
	}
}

/* 月日セレクト */
.c-field-date {
	display: flex;
	justify-content: flex-start;

	&__item {
		flex: 1;
	}

	&__item + &__item {
		margin-left: 10px;
	}

	@include m.mq-pc {
		&__item + &__item {
			margin-left: 50px;
		}
	}
}

/* 郵便番号入力用（検索ボタンも含む） */
.c-field-zip {
	&__items {
		display: flex;
		align-items: flex-start;
	}

	&__item {
		flex: 1;
	}

	&__hyphen {
		display: flex;
		align-items: center;
		margin: 0 8px;
		line-height: 50px;
	}

	&__search {
		display: block;
		margin: 15px auto 0;
		max-width: 160px;

		&-inner {
			display: flex;
			align-items: center;
			width: 100%;
			min-height: 50px;
			border: 2px solid #3B4043;
			border-radius: 5px;
			font-weight: bold;
			color: #3B4043;
			cursor: pointer;

			> span {
				display: block;
				width: 100%;
				padding: 11px 10px;
				text-align: center;
				font-size: 1.6rem;
				line-height: var(--line-height-s);
				color: inherit;
			}
		}
	}

	@include m.mq-pc {
		display: flex;
		align-items: flex-start;
		flex-wrap: wrap;
		margin-bottom: -16px;

		&__items {
			flex: 1;
			margin: 0 32px 16px 0;
		}

		&__item {
			min-width: 100px;
		}

		&__search {
			flex-basis: 160px;
			margin: 0 0 16px;

			&-inner {
				transition: background-color .2s, color .2s;

				&:hover {
					background-color: #3B4043;
					color: #fff;
				}
			}
		}
	}
}

/* 電話番号入力用 */
.c-field-tel {
	display: flex;
	align-items: flex-start;
	justify-content: center;

	&__item {
		flex: 1;
	}

	&__hyphen {
		display: flex;
		align-items: center;
		margin: 0 8px;
		line-height: 50px;
	}
}
