@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	inputset
----------*/
.c-inputset {
	&__required {
		display: inline-block;
		margin-left: 10px;
		font-size: 1.2rem;
		font-weight: 500;
		line-height: 1;
		letter-spacing: -.41px;
		color: var(--color-caution);

		&::before {
			content: '※';
			display: inline-block;
			font-size: inherit;
			color: var(--color-caution);

		}
	}

	&__note {
		margin-bottom: 20px;
		font-size: 1.4rem;
		line-height: var(--line-height-l);
		color: var(--color-txt);
	}

	.parsley-errors-list {
		font-size: 1.3rem;
		line-height: 1.3;
		color: var(--color-caution);

		&.filled {
			margin-top: 8px;
		}
	}

	&__inner,
	&__body,
	&__row,
	&__detail {
		display: block;
		width: 100%;
	}

	&__row {
		overflow: hidden;
		margin-top: 30px;

		&:first-child {
			margin-top: 0;
		}

		&--cf {
			margin-top: 10px;
		}

		&--border {
			border-top: 1px solid var(--color-border);
			margin-top: 60px;
			padding-top: 60px;
		}
	}

	&__header,
	&__subheader,
	&__detail {
		clear: both;
		float: left;
		text-align: left;
	}

	&__header {
		vertical-align: middle;
		font-size: 1.4rem;
		font-weight: bold;
		color: var(--color-txt);
		text-align: left;
		margin-bottom: 15px;
	}

	&__errors {
		font-size: 1.3rem;
		font-weight: bold;
		line-height: 1.3;
		color: var(--color-caution);
		margin-top: 8px;
	}

	// &__subheader {
	// 	display: block;
	// 	width: 100%;
	// 	padding: 10px 16px;
	// 	font-size: 1.4rem;
	// 	background: var(--color-bg);
	// 	color: var(--color-txt-caption-v2);
	// }

	@include m.mq-pc {
		&__inner {
			margin: 0 auto;
			max-width: 780px;
		}

		&__header {
			font-size: 1.6rem;

			&--top {
				vertical-align: top;
			}
		}


		&__row {
			margin-top: 40px;

			&--cf {
				margin-top: 20px;
			}

			&--border {
				border-top: 1px solid var(--color-border);
				margin-top: 80px;
				padding-top: 80px;
			}
		}

		// &__subheader {
		// 	vertical-align: middle;
		// 	width: 240px;
		// 	// padding: 37px 16px;
		// 	padding: 18px 16px;
		// 	font-size: 1.8rem;
		// 	background: var(--color-bg);
		// 	color: var(--color-txt-caption-v2);
		// 	text-align: left;
		// 	border: 1px solid #FFF;
		// 	border-top: 1px solid #FFF;
		// 	border-bottom: 1px solid #FFF;
		// 	border-right: none;
		// 	border-left: none;

		// 	&--top {
		// 		vertical-align: top;
		// 	}
		// }

		// &__detail {
		// 	padding: 18px 47px;
		// 	border: 1px solid #CDD6DD;
		// 	border-top: 1px solid #CDD6DD;
		// 	border-bottom: 1px solid #CDD6DD;
		// 	border-right: 1px solid #CDD6DD;
		// 	border-left: none;
		// }

		&__item {
			&--300 {
				max-width: 300px;
			}

			&--365 {
				max-width: 365px;
			}

			// &--400 {
			// 	max-width: 400px;
			// }

			// &--420 {
			// 	max-width: 420px;
			// }

			// &--460 {
			// 	max-width: 460px;
			// }
		}

		&__required {
			margin-left: 20px;
			font-size: 1.3rem;
		}

		&__note {
			font-size: 1.6rem;
		}

		.parsley-errors-list {
			font-size: 1.5rem;
		}

		&__errors {
			font-size: 1.5rem;
			line-height: 1.5;
			margin-top: 15px;
		}
	}
}
