@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	billboard
----------*/

.c-billboard {
	position: relative;
	z-index: 1;

	&__inner {
		@include m.inner-content;
		height: 350px;
		display: flex;
		flex-direction: column-reverse;
		flex-wrap: wrap;

		@include m.mq-pc {
			flex-direction: row;
			align-items: center;
			height: 500px;
		}
	}

	&__title {
		font-weight: 500;
		font-size: 2.4rem;
		color: #fff;
		line-height: var(--line-height-s);

		@include m.mq-sp {
			padding-bottom: 52px;
		}

		@include m.mq-pc {
			font-size: 4.2rem;
			padding-top: 115px;
		}

		&-sub {
			font-weight: bold;
			font-size: 1.6rem;
			color: var(--color-txt-caption-v2);

			@include m.mq-pc {
				font-size: 2rem;
			}
		}
	}

	&__lead {
		margin-top: 12px;
		font-size: 1.4rem;
		color: var(--color-txt-caption-v2);
		line-height: var(--line-height-l);

		@include m.mq-pc {
			font-size: 1.8rem;

		}
	}

	&__image {
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		z-index: -1;

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
