@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	imageset
----------*/

.c-imageset {
	&__image {
		overflow: hidden;
		border-radius: 5px;
	}

	&__title {
		font-weight: bold;
		font-size: 1.6rem;
		line-height: var(--line-height-s);
		margin-bottom: 20px;
	}

	&__button {
		max-width: percentage( math.div(300, 336) );
		margin: 30px auto 0;
		padding: 0 10px;
	}

	@include m.mq-sp {
		&__image {
			margin-bottom: 20px;
		}

		&--variable {
			.c-imageset__image {
				> img {
					width: auto;
					max-width: 100%;
				}
			}
		}
	}

	@include m.mq-pc {
		&__inner {
			display: flex;
			justify-content: space-between;
			align-items: flex-start;
		}

		&__image {
			flex: 0 0 calc(50% - 16px);
			margin-right: 32px;
		}

		&__title {
			font-size: 2rem;
		}

		&__content {
			flex: 1 0 calc(50% - 16px);
		}

		&__button {
			max-width: 312px;
			margin-top: 40px;
			margin-left: 0;
			padding: 0;
		}

		&--1t2 {
			.c-imageset__image {
				flex-basis: calc(33.3333% - 16px);
			}
		}

		&--1t3 {
			.c-imageset__image {
				flex-basis: calc(25% - 16px);
			}
		}

		&--reverse {
			.c-imageset__inner {
				flex-direction: row-reverse;
			}

			.c-imageset__image {
				margin-right: 0;
				margin-left: 32px;
			}
		}

		&--variable {
			.c-imageset__image {
				flex-basis: auto;
				max-width: 50%;
			}

			&__content {
				flex-basis: calc(50% - 32px);
			}
		}
	}
}
