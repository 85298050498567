@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	order list
----------*/
$_root: '.c-orderlist';

.c-orderlist {
	counter-reset: orderlist;

	&__item {
		padding-left: 2em;
		text-indent: -2.5em;

		&::before {
			counter-increment: orderlist;
			content: counter(orderlist)'.';
			display: inline-block;
			margin-right: .5em;
			min-width: 2em;
			text-align: right;
		}
	}

	&__item + &__item {
		margin-top: 10px;
	}

	&--large {
		#{$_root}__item {
			padding-left: 3em;
			text-indent: -3.5em;
			font-size: 1.6rem;
			font-weight: 500;

			&::before {
				counter-increment: orderlist;
				content: counter(orderlist)'.';
				display: inline-block;
				font-size: 2rem;
				font-family: var(--font-inter);
				color: var(--color-prim);
				margin-right: 16px;
				min-width: 2em;
				text-align: right;
			}
		}

		@include m.mq-pc {
			#{$_root}__item {
				padding-left: 3.5em;
				text-indent: -4.5em;
				font-size: 2rem;

				&::before {
					font-size: 2.4rem;
					margin-right: 43px;
				}
			}
		}

		#{$_root}__item + #{$_root}__item {
			margin-top: 15px;

			@include m.mq-pc {
				margin-top: 20px;
			}
		}
	}
}
