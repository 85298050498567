@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-secondary
----------*/

.c-heading-secondary {
	font-weight: 500;
	font-size: 2rem;
	line-height: var(--line-height-m);
	position: relative;

	@include m.mq-pc {
		font-size: 2.8rem;
	}

	& > *[id] {
		margin-top: -90px;
		padding-top: 90px;

		@include m.mq-pc {
			margin-top: -100px;
			padding-top: 100px;
		}
	}

	&::before {
		content: '';
		display: block;
		width: 40px;
		height: 3px;
		background: var(--color-prim);
		margin-bottom: 13px;

		@include m.mq-pc {
			width: 80px;
		}
	}

	&::after {
		content: '';
		display: block;
		width: calc(100% - 50px);
		height: 1px;
		background: var(--color-border);
		position: absolute;
		top: 1px;
		right: 0;

		@include m.mq-pc {
			width: calc(100% - 90px);
		}
	}
}
