@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	news
----------*/
$_root: '.c-news';

.c-news {
	> a {
		display: inline-block;
		border-color: #999;
		border-color: var(--color-txt-link);
		color: var(--color-txt);
	}

	&__detail {
		display: grid;
		place-items: center start;
		grid-template-columns: auto 1fr;
		grid-template-rows: auto auto;
		// display: flex;
		// flex-wrap: wrap;
		// justify-content: flex-start;
		padding: 16px 0;
	}

	&__date {
		grid-column: 1;
		grid-row: 1;
		// flex-shrink: 0;
		width: 80px;
		margin-right: 16px;
		font-family: var(--font-inter);
		font-size: 1.3rem;
		line-height: var(--line-height-m);
		vertical-align: middle;
	}

	&__tag {
		grid-column: 2;
		grid-row: 1;
	}

	&__headline {
		grid-column: 1/3;
		grid-row: 2;
		// flex: 0 0 100%;
		font-size: 1.4rem;
		line-height: var(--line-height-m);
		// color: var(--color-txt);

		> i[class^='icon-'] {
			color: var(--color-txt);
		}
	}

	&__title {
		> i[class^='icon-'] {
			text-indent: 0;
			margin-right: .2em;
			transform: translateY(.15em);
		}
	}

	a#{&}__title {
		display: inline-block;
		padding-left: 1.2em;
		text-indent: -1.2em;
	}

	&__text {
		font-size: 1.4rem;
	}

	&__file {
		display: inline-block;
		line-height: var(--line-height-m);
		margin-left: 8px;
		font-size: 1.4rem;
		text-indent: 0;
		color: #999;
	}

	@include m.mq-sp {
		&__date {
			margin-bottom: 8px;
		}

		&__tag {
			margin-bottom: 8px;
		}
	}

	@include m.mq-pc {
		> a {
			#{$_root}__headline {
				transition: color .2s;
			}

			&:hover {
				#{$_root}__headline {
					text-decoration: underline;
					color: var(--color-txt-link);
				}
			}
		}

		&__detail {
			grid-template-columns: auto auto 1fr;
			grid-template-rows: auto;
			padding-top: 24px;
			padding-bottom: 24px;
		}

		&__date {
			width: 90px;
		}

		&__tag {
			margin-right: 8px;
			margin-top: 10px;
		}

		&__headline {
			margin-top: 10px;
		}

		&__title {
			> i[class^='icon-'] {
				font-size: 1.8rem;
			}
		}

		a#{&}__title {
			transition: color .2s;

			&:hover {
				color: var(--color-txt-hover);
			}
		}

		&__text {
			font-size: 1.6rem;
		}

		@supports (display: grid) {
			&__detail {
				grid-template-columns: auto auto 1fr;
				grid-template-rows: auto;
			}

			&__date {
				grid-column: 1;
				grid-row: 1;
				font-size: 1.5rem;
			}

			&__tag {
				grid-column: 2;
				grid-row: 1;
				margin-top: 2px;
			}

			&__headline {
				grid-column: 3;
				grid-row: 1;
				margin-top: 0;
			}
		}
	}
}
