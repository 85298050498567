@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	icon container
----------*/

.g-icon-container {
	margin-top: -8px;
	font-size: 0;

	&__item {
		display: inline-block;
		margin-right: 24px;
		margin-top: 8px;
	}
}
