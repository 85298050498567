@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-qa
----------*/
$_root: '.c-qa';

.c-qa {
	border-bottom: 1px solid var(--color-border);

	&__item {
		position: relative;
		margin-top: -1px;

		&--parent {
			border-top: 1px solid var(--color-border);
			cursor: pointer;

			&::after {
				@include m.iconfont-default;
				content: var(--icon-plus);
				font-size: 1.5rem;
				position: absolute;
				top: 50%;
				right: 14px;
				transform: translateY(-50%);
				pointer-events: none;
			}
		}

		&--child {
			// display: none;
			height: 0;
			position: relative;
			overflow: hidden;
			background-color: #fff;
			opacity: 0;
			pointer-events: none;
			transition: opacity 0.2s linear;
		}

		&-inner {

			position: relative;
			display: flex;

			&::before {
				content: attr(title);
				font-family: var(--font-inter);
				font-size: 2.4rem;
				font-weight: bold;
				line-height: 1;
				display: inline-block;
				vertical-align: middle;
			}

			&--parent {
				font-weight: 500;
				font-size: 1.5rem;
				line-height: var(--line-height-s);
				padding: 20px 48px 21px 10px;

				&::before {
					padding-right: 12px;
					color: var(--color-prim);
				}

			}

			&--child {
				font-size: 1.4rem;
				min-height: 104px;
				padding: 16px 21px 30px 40px;

				&::before {
					padding-right: 11px;
					color: var(--color-txt);
				}
			}
		}

		&-txt {
			flex: 1;
		}
	}

	@include m.mq-pc {
		&__item {
			&--parent {
				&::after {
					font-size: 1.8rem;
					right: 16px;
				}
			}

			&-inner {
				padding: 25px 40px;

				&--parent {
					color: var(--color-txt);
					font-size: 1.8rem;

					&::before {
						padding-right: 28px;
					}
				}

				&--child {
					padding: 25px 93px 32px 90px;
					font-size: 1.6rem;

					&::before {
						padding-right: 19px;
						transform: translateY(3px);
					}
				}
			}
		}
	}

	@media (hover: hover) and (pointer: fine) {
		&__item {
			&--parent {
				&::after {
					transition: color 0.2s linear;
				}

				&:hover {
					&::after {
						color: var(--color-prim);
					}
				}
			}

			&-inner {
				&--parent {
					transition: color 0.2s linear;

					&:hover {
						color: var(--color-prim);
					}
				}
			}
		}
	}

	&.is-active {
		#{$_root}__item--parent {
			background-color: #fff;

			&::after {
				content: var(--icon-minus);
			}
		}

		#{$_root}__item--child {
			height: auto;
			opacity: 1;
			pointer-events: all;
		}
	}
}
