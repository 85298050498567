@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	list
----------*/
$_root: '.c-list';

.c-list {
	&__item {
		padding-left: 1em;
		text-indent: -1em;

		&::before {
			content: '';
			display: inline-block;
			width: .3em;
			height: .3em;
			margin-right: .75em;
			border-radius: 50%;
			vertical-align: middle;
			transform: translate(50%, -50%);
			background-color: var(--color-txt);
		}
	}

	&--normal {
		#{$_root}__item {
			padding-left: 0;
			text-indent: 0;

			&::before {
				content: none;
			}
		}
	}

	&__item + &__item {
		margin-top: 10px;
	}
}
