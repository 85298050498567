@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	speciallink
----------*/
$_root: '.c-speciallink';

.c-speciallink {
	position: relative;

	&__inner {
		border-radius: 5px;
		overflow: hidden;
		background-color: #fff;
	}

	&__content {
		position: relative;
		padding: 20px 30px 40px;
		background: var(--color-prim);
		z-index: 1;
	}

	&__title {
		display: flex;
		align-items: center;
		font-weight: 500;
		font-size: 3rem;
		line-height: var(--line-height-s);
		color: #FFFFFF;
		margin-bottom: 20px;

		> i {
			margin-right: 12px;
		}
	}

	&__text {
		font-weight: 500;
		color: #fff;
	}

	&__button {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 60px;
		height: 60px;
		position: absolute;
		right: 20px;
		bottom: -30px;

		&::after {
			content: '';
			display: block;
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			border-radius: 50%;
			box-shadow: 0 2px 4px 0 rgba(0,0,0,.15);
			background: #fff;
		}

		> i {
			color: var(--color-prim);
			font-size: 1.2rem;
			z-index: 2;
		}
	}

	&__image {
		// 画像比率固定用の記述
		width: 100%;
		height: 0;
		padding-bottom: percentage( math.div(1, 2.368) );
		position: relative;
		overflow: hidden;

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&__linkarea {
		padding: 20px 30px;
	}

	&--v2 {
		#{$_root}__content {
			background: var(--color-seco);
		}

		#{$_root}__button {
			color: var(--color-seco);

			> i {
				color: var(--color-seco);
			}
		}

		#{$_root}__icon {
			color: var(--color-seco);

			&::after {
				border-color: var(--color-seco);
			}
		}
	}

	&--v3 {
		#{$_root}__content {
			background: var(--color-seco2);
		}

		#{$_root}__button {
			color: var(--color-seco2);

			> i {
				color: var(--color-seco2);
			}
		}

		#{$_root}__icon {
			color: var(--color-seco2);

			&::after {
				border-color: var(--color-seco2);
			}
		}
	}

	@include m.mq-pc {

		&__titlearea {
			display: flex;

			a {
				#{$_root}__button {

					&::after {
						transition: transform .3s ease-out;
					}
				}

				&:hover {
					#{$_root}__button {
						&::after {
							transform: scale(1.2);
						}
					}
				}
			}
		}

		&__content {
			width: 480px;
			border-radius: 5px 0 10px;
			overflow: hidden;
			padding: 50px 50px 40px;
		}

		&__title {
			font-size: 3.6rem;
			margin-bottom: 74px;

			> i {
				margin-right: 18px;
			}
		}

		&__detail {
			padding-right: 140px;
		}

		&__button {
			right: 30px;
			bottom: 30px;
			width: 100px;
			height: 100px;

			> i {
				font-size: 2rem;
			}
		}

		&__image {
			height: 359px;
			padding-bottom: 0;
			border-radius: 0 5px;
			overflow: hidden;
			margin-left: -250px;
		}

		&__linkarea {
			padding: 30px;
		}

		&--v2 {
			#{$_root}__textlink {
				&:hover {
					color: var(--color-seco);
				}
			}
		}

		&--v3 {
			#{$_root}__textlink {
				&:hover {
					color: var(--color-seco2);
				}
			}
		}
	}
}

.c-linkarea {
	@include m.mq-pc {
		&__inner {
			display: flex;
			flex-wrap: nowrap;
			justify-content: flex-end;
		}

		&__item {
			flex: 0 1 262px;
			margin: 0;
		}

		&__item + &__item {
			margin-left: 30px;
		}
	}
}
