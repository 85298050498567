@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	text-attention
----------*/

.c-text-attention {
	font-size: 1.4rem;
	line-height: var(--line-height-m);
	color: var(--color-caution);

	> a {
		text-decoration: underline;
	}

	&--bold {
		font-weight: bold;
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		> a {
			transition: all .2s;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
