@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	heading-primary
----------*/

.c-heading-primary {
	font-weight: 500;
	font-size: 2.4rem;
	line-height: var(--line-height-s);
	padding-top: 40px;

	&__category {
		font-weight: 500;
		font-size: 1.6rem;
		color: var(--color-prim);
		margin-bottom: 10px;
	}

	@include m.mq-pc {
		padding-top: 56px;
		font-size: 4.2rem;

		&__category {
			font-size: 2rem;
			margin-bottom: 6px;
		}
	}
}
