@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	single column
----------*/
$_root: '.g-center-col';

.g-center-col {

	&__item + &__item {
		margin-top: 20px;
	}

	@include m.mq-pc {
		display: flex;
		justify-content: center;
		align-items: flex-start;

		&__item {
			flex: 1 1 percentage( math.div(2,3) );
			max-width: percentage( math.div(2,3) ); // コンテンツ幅の3/2想定（1200に対して800）
		}

		&__item + &__item {
			margin-top: 0;
		}

		&--small {
			#{$_root}__item {
				// flex-basis: percentage( math.div(1,3) );
				// max-width: percentage( math.div(1, 3) );
				flex-basis: percentage( math.div(1,3) );
				max-width: 550px;
			}
		}

		&--2up {
			#{$_root}__item {
				flex-basis: percentage( math.div(1,3) );
				max-width: percentage( math.div(1, 3) );
			}

			#{$_root}__item + #{$_root}__item {
				margin-left: 50px;
			}
		}

		&--3up {
			#{$_root}__item {
				flex-basis: percentage( math.div(1,3) );
				max-width: percentage( math.div(1, 3) );
			}

			#{$_root}__item + #{$_root}__item {
				margin-left: 50px;
			}
		}
	}
}
