@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	construction
----------*/
$_root: '.c-construction';

.c-construction {
	position: relative;
	border-radius: 5px;
	overflow: hidden;

	&::before {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background: url(/assets/images/bg_construction_sp.png) top center no-repeat;
		background-size: contain;
	}

	&::after {
		content: '';
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
		height: 100%;
		background-color: rgba($color: var(--color-black-rgb), $alpha: .4);
	}

	&__title {
		position: relative;
		display: inline-block;
		min-width: 220px;
		padding: 13px 0 17px 20px;
		background: var(--color-black);
		border: 1px solid var(--color-black);
		box-sizing: border-box;
		border-radius: 0 0 10px;
		z-index: 1;

		p {
			color: #fff;
			font-weight: 500;
			font-size: 2rem;
			line-height: var(--line-height-s);
		}
	}

	&__inner {
		position: relative;
		width: 100%;
		margin: 0 auto;
		padding: 30px 20px 40px;
		z-index: 1;
	}

	&__text {
		border-left: 1px solid #FFFFFF;
		padding-left: 20px;
		color: #fff;
		font-weight: 500;
	}

	&__list {
		width: 100%;
		margin-top: 40px;
		color: #fff;
		font-weight: 500;
	}

	&-orderlist {
		margin-top: -10px;

		&__item {
			margin-top: 10px;
		}
	}


	@include m.mq-pc {
		&::before {
			background: url(/assets/images/bg_construction.png) center no-repeat;
			background-size: cover;
		}

		&__title {
			min-width: 345px;
			padding: 24px 50px 25px;

			p {
				font-size: 2.8rem;
			}
		}

		&__inner {
			padding: 50px 60px 77px;
		}

		&-orderlist {
			display: flex;
			flex-wrap: wrap;
			justify-content: space-between;

			&__item {
				flex: 0 0 calc(50% - 10px);
			}
		}
	}
}
