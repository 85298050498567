@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	news list
----------*/

.g-news-container {
	&__item {
		border-bottom: 1px solid #ccc;

		&:first-child {
			border-top: 1px solid #ccc;
		}

		// + .c-news-list__item {
		// 	margin-top: 16px;
		// }
	}
}
