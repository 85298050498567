@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	important-info
----------*/
$_root: '.c-important-info';

.c-important-info {
	padding: 20px;
	position: relative;
	background-color: #fff;
	border-radius: 5px;


	&__item + &__item {
		margin-top: 30px;
	}

	&__content {
		margin-top: 8px;
		line-height: var(--line-height-s);
		color: var(--color-txt);
		font-size: 1.4rem;
	}

	@include m.mq-pc {
		padding: 22px 20px;

		&__item + &__item {
			margin-top: 16px;
		}

		&__detail {
			display: flex;
			justify-content: flex-start;
			align-items: center;
		}

		&__date {
			flex: 0 0 6.5em;
		}

		&__content {
			margin-top: -.15em;
			font-size: 1.6rem;
		}

		a {
			#{$_root}__content {
				p {
					color: var(---color-txt);
					text-decoration: none;
					transition: color .2s;
				}
			}

			&:hover {
				#{$_root}__content {
					p {
						color: var(--color-caution);
						text-decoration: underline;
					}
				}
			}
		}
	}
}
