@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	image fit
----------*/

.c-image-fit {
	height: 0;
	padding-bottom: percentage( math.div(2, 3) );
	position: relative;
	border-radius: 5px;
	overflow: hidden;

	> img,
	> iframe,
	> video,
	> object,
	> embed,
	&__element {
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		object-fit: cover;
		object-position: center;
	}

	&--2t1 {
		padding-bottom: percentage( math.div(1, 2) );
	}

	&--16t9 {
		padding-bottom: percentage( math.div(9, 16) );
	}

	&--sq {
		padding-bottom: 100%;
	}
}
