@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	youtube
----------*/
$_root: '.c-embed';

.c-embed {
	position: relative;

	&.is-play {
		#{$_root}__poster {
			display: none;
		}
	}

	> video {
		display: block;
		width: 100%;
		height: auto;
	}

	> iframe {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}

	&__poster {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		cursor: pointer;

		> button {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 2;
			margin: auto;
			width: 50px;
			height: 50px;
			font-size: 0;
			background: url(/assets/images/icon_play.svg) no-repeat center;
			cursor: pointer;
		}

		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}

	&--w800 {
		max-width: 800px;
		margin: 0 auto;
	}

	&--iframe {
		position: relative;
		width: 100%;
		height: 0;
		padding-top: 56.25%;

		@include m.mq-pc {
			padding-top: 450px;
		}
	}

	&-caption {
		margin-top: 15px;
		text-align: left;
		font-size: 1.2rem;
		line-height: var(--line-height-l);
		color: var(--color-txt);

		@include m.mq-pc {
			font-size: 1.4rem;
		}
	}
}
