@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-point
----------*/

.c-point {

	text-align: center;

	&__item {
		position: relative;
		padding: 40px 15px 0;

		& + .c-point__item {
			margin-top: 50px;
		}
	}

	&__icon {
		background: var(--color-txt-caption);
		border-radius: 100%;
		color: #fff;
		font-weight: bold;
		line-height: 1;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		position: absolute;
		top: 0;
		left: 50%;
		transform: translateX(-50%);

		&-title {
			font-size: 1.4rem;
			display: block;
		}

		&-num {
			font-size: 24px;
			display: block;
			margin-top: 3px;

			&:only-child {
				margin-top: 0;
			}
		}
	}

	&__image {
		> img {
			width: auto;
		}
	}

	&__text {
		margin-top: 15px;
		font-size: 2rem;
		font-weight: bold;
	}

	@include m.mq-pc {

		display: flex;
		flex-wrap: wrap;
		justify-content: center;

		&__item {
			width: 360px;
			padding: 40px 30px;

			& + .c-point__item {
				margin-top: 0;
			}
		}
	}
}
