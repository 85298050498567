@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	checkbox-container
----------*/
$_root: '.g-checkbox-container';

.g-checkbox-container {
	&__item {
		display: table;
	}

	&__item + &__item {
		margin-top: 16px;
	}

	&--flex {
		display: block;

		@include m.mq-pc {
			display: flex;
			flex-wrap: wrap;
			margin-top: -20px;

			#{$_root}__item {
				margin-top: 20px;

				&:not(:last-of-type) {
					margin-right: 20px;
				}
			}
		}
	}
}
