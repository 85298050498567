@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	feature
----------*/
$_root: '.c-card-feature';

.c-card-feature {

	&__inner {
		display: block;
		height: 100%;
		position: relative;
		background-color: #fff;
		cursor: pointer;
		border-radius: 5px;
		overflow: hidden;

		&::before {
			content: '';
			display: block;
			width: 100%;
			height: 45%;
			position: absolute;
			bottom: 0;
			z-index: 1;
			background-image: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0,0,0,.65) 100%);
		}
	}

	&__image {

		// 画像比率固定用の記述
		width: 100%;
		height: 0;
		padding-bottom: percentage( math.div(9, 16) ); // 画像比率固定 9:16想定
		position: relative;
		overflow: hidden;

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&__content {
		width: 100%;
		padding: 15px;
		position: absolute;
		bottom: 0;
		left: 0;
		z-index: 2;
	}

	&__title {
		position: relative;
		width: 100%;
		color: #fff;
		font-weight: bold;
		font-size: 1.6rem;
		line-height: var(--line-height-s);

		> span {
			margin-right: 20px;
		}

		> i[class^='icon-'] {
			position: absolute;
			bottom: 0;
			right: 0;
			color: #fff;
			font-size: 1.6rem;
			line-height: 1.2;
		}
	}

	@include m.mq-pc {
		&__image {
			> img {
				transform: scale(1);
				transition: transform .3s ease-out;
			}
		}

		&__inner {
			&:hover {
				#{$_root}__image {
					> img {
						transform: scale(1.05);
					}
				}
			}
		}

		&__content {
			padding: 26px 60px 26px 40px;
		}

		&__title {
			font-size: 2.4rem;

			> i[class^='icon-'] {
				right: -30px;
				font-size: 2.4rem;
				vertical-align: 3px;
			}

			&--small {
				font-size: 2rem;

				> i[class^='icon-'] {
					font-size: 1.8rem;
				}
			}
		}
	}
}
