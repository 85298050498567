@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	image link
----------*/
$_root: '.c-imgset-products';

.c-imgset-products {
	position: relative;

	&__inner {
		position: relative;
		background-color: #fff;
		overflow: hidden;
		border-radius: 5px;
		padding: 20px 20px 32px;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);
	}

	&__image {
		grid-area: image;
		width: 100px;
		height: 100px;
		margin-right: 24px;
		overflow: hidden;
		border-radius: 5px;

		> img {
			display: block;
			width: 100%;
			height: 100%;
			object-fit: cover;
			transition: transform .3s ease-out;
		}
	}

	&__top {
		@include m.margin-trim;
		grid-area: title;
	}

	&__category {
		margin-bottom: 10px;
	}

	&__title {
		font-weight: bold;
		font-size: 1.6rem;
		line-height: var(--line-height-s);
		color: var(--color-txt);
		transition: color .2s;
	}

	&__label {
		margin-bottom: 20px;
	}

	&__text {
		font-size: 1.4rem;
		color: var(--color-txt-caption);
		line-height: var(--line-height-l);
	}

	&__bottom {
		grid-area: text;
	}

	&__tags {
		display: flex;
		margin: 7px 0 2px;
	}

	&__tag {
		min-width: 81px;
		padding: 8px 15px 9px;
		border: 1px solid var(--color-border);
		border-radius: 16px;
		font-size: 1.3rem;
		line-height: 1;
		font-weight: 500;
		text-align: center;
		color: var(--color-txt-caption);

		+ #{$_root}__tag {
			margin-left: 10px;
		}
	}

	&__icon {
		position: absolute;
		right: 15px;
		bottom: 15px;
		width: 18px;
		height: 18px;
		font-size: 1.8rem;
	}

	@include m.mq-sp {
		/* stylelint-disable declaration-block-no-redundant-longhand-properties */
		&__inner {
			display: grid;
			grid-template-columns: 100px 1fr;
			grid-template-rows: auto 1fr;
			gap: 30px 20px;
			/* stylelint-disable declaration-colon-space-after */
			grid-template-areas:
				'image title'
				'text text';
		}

		&__image {
			grid-row: 1/2;
		}
	}

	@include m.mq-pc {
		&__inner {
			display: grid;
			padding: 20px 32px 46px 20px;
			grid-template-columns: 140px 1fr;
			grid-template-rows: auto 1fr;
			gap: 0 40px;
			grid-template-areas:
				'image title'
				'image text';
			height: 100%;
		}

		&__image {
			width: 140px;
			height: 140px;
			grid-row: 1/3;
		}

		&__title {
			font-size: 2rem;
		}

		&__bottom {
			margin-top: 20px;
		}

		&__icon {
			right: 20px;
			bottom: 20px;
		}

		&:hover {
			#{$_root}__title {
				color: var(--color-txt-caption);
			}

			#{$_root}__image {
				> img {
					transform: scale(1.05);
				}
			}
		}
	}
}
