@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	caption
----------*/

.c-caption {
	font-size: 1.2rem;
	line-height: var(--line-height-l);
	color: var(--color-txt);

	> a {
		text-decoration: underline;
	}

	@include m.mq-pc {
		font-size: 1.4rem;

		> a {
			transition: all .2s;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
