@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	youtube
----------*/
.c-youtube {
	position: relative;

	&::before {
		content: '';
		display: block;
		padding-top: percentage(math.div(360,640));
	}

	> iframe {
		display: block;
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}
