@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	button
----------*/
$_root: '.c-button';

/* stylelint-disable no-duplicate-selectors */
.c-button {

	&__inner {
		display: flex;
		width: 100%;
		height: 100%;
		min-height: 54px;
		flex-wrap: nowrap;
		justify-content: center;
		align-items: center;
		position: relative;
		overflow: hidden;
		background-color: #333;
		color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0,0,0,.05);
		border-radius: 27px;
		cursor: pointer;

		> span {
			padding: 8px 40px;
			text-align: center;
			font-size: 1.4rem;
			font-weight: bold;
			line-height: var(--line-height-s);
			color: inherit;
		}
	}

	&__icon {
		width: 1em;
		height: 1em;
		margin: auto;
		position: absolute;
		top: 0;
		bottom: 0;
		right: 16px;
		line-height: 1;
		color: inherit;
	}

	@include m.mq-pc {
		&__inner {
			transition: background-color .2s, color .2s, border-color .2s;
			// transition: background-color .2s;

			&:hover {
				background-color: var(--color-prim);
			}

			> span {
				font-size: 1.6rem;
			}
		}
	}
}

/* バリエーション */

/* color */
.c-button {
	// 	&--seco {
	// 		#{$_root}__inner {
	// 			background-color: var(--color-seco);
	// 			color: var(--color-txt);
	// 		}
	// 	}

	// 	@include m.mq-pc {
	// 		&--seco {
	// 			#{$_root}__inner {
	// 				&:hover {
	// 					background-color: rgba($color: var(--color-seco-rgb), $alpha: .6);
	// 				}
	// 			}
	// 		}
	// 	}
	// }

	/* size */
	// .c-button {
	// 	&--l {
	// 		#{$_root}__inner {
	// 			min-height: 60px;

	// 			> span {
	// 				font-size: 1.8rem;
	// 				font-weight: bold;
	// 			}
	// 		}
	// 	}

	&--s {
		#{$_root}__inner {
			min-height: 40px;

			> span {
				font-size: 1.4rem;
			}

			@include m.mq-pc {
				> span {
					font-size: 1.6rem;
				}
			}
		}
	}
}

/* border */
.c-button {
	&--border {
		#{$_root}__inner {
			border: 1px solid var(--color-txt);
			background-color: transparent;
			color: var(--color-txt);

			> span {
				font-weight: 500;
			}
		}

		#{$_root}__icon {
			color: var(--color-prim);
		}
	}

	@include m.mq-pc {
		&--border {
			#{$_root}__inner {
				&:hover {
					border-color: var(--color-prim);
					background-color: var(--color-prim);
					color: #fff;

					#{$_root}__icon {
						color: #fff;
					}
				}
			}
		}
	}
}

/* cv */
.c-button {
	&--cv {
		#{$_root}__inner {
			min-height: 60px;
			background-color: var(--color-prim);
			border-radius: 32px;
			// font-size: 1.6rem;

			> span {
				padding: 8px 0;
				font-size: 1.5rem;
			}
		}

		#{$_root}__icon {
			position: relative;
			width: 2rem;
			height: 2rem;
			margin: 0;

			&::before {
				font-size: 2rem;
			}
		}
	}
	@include m.mq-pc {
		&--cv {
			#{$_root}__inner {

				> span {
					font-size: 2rem;
				}

				&:hover {
					background-color: #00CCFF;
				}
			}

			#{$_root}__icon {
				width: 2.4rem;
				height: 2.4rem;

				&::before {
					font-size: 2.4rem;
				}
			}
		}
	}
}

/* cv */
.c-button {
	&--cv2 {
		#{$_root}__inner {
			position: relative;
			min-height: 60px;
			background-color: var(--color-prim);
			border-radius: 32px;

			> span {
				padding: 8px 60px 8px 40px;
				font-size: 1.5rem;
			}
		}

		#{$_root}__icon {
			position: absolute;
			top: 50%;
			right: 23px;
			width: 1.4rem;
			height: 1.4rem;
			margin: 0;
			transform: translateY(-50%);

			&::before {
				font-size: 1.4rem;
			}
		}
	}

	@include m.mq-pc {
		&--cv2 {
			#{$_root}__inner {
				> span {
					font-size: 2rem;
				}

				&:hover {
					background-color: #00CCFF;
				}
			}

			#{$_root}__icon {
				width: 1.8rem;
				height: 1.8rem;

				&::before {
					font-size: 1.8rem;
				}
			}
		}
	}
}

/* textlink */
.c-button {
	&--textlink {
		#{$_root}__inner {
			justify-content: flex-start;
			background-color: transparent;
			color: var(--color-txt);
			font-weight: 500;
			border-radius: 0;
			box-shadow: none;
			padding-right: 25px;

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				border-bottom: 1px solid #D8D8D8;
			}

			> span {
				padding: 8px 24px 8px 0;
				text-align: left;
				font-size: 1.4rem;
			}
		}

		#{$_root}__icon {
			display: flex;
			justify-content: center;
			align-items: center;
			width: 25px;
			height: 100%;
			right: 0;
			color: var(--color-prim);

			&::after {
				content: '';
				position: absolute;
				bottom: 0;
				left: 0;
				width: 100%;
				height: 1px;
				border-bottom: 1px solid var(--color-prim);
				z-index: 1;
			}
		}
	}

	@include m.mq-pc {
		&--textlink {
			#{$_root}__inner {
				transition: color .2s;

				&:hover {
					background-color: transparent;
					color: var(--color-txt-link);
				}

				> span {
					padding-top: 18px;
					padding-bottom: 18px;
					font-size: 1.6rem;
				}
			}
		}
	}
}
/* stylelint-enable no-duplicate-selectors */
