@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	card
----------*/
$_root: '.c-card';

.c-card {
	// box-shadow: 0 0 5px 0 rgba(0, 0, 0, .2);

	&__inner {
		height: 100%;
		display: block;
		border: 1px solid var(--color-border);
	}

	&__image {
		margin: -1px -1px 12px;
		// 画像比率固定用の記述
		// width: calc(100% + 2px);
		height: 0;
		padding-bottom: 50%; // 画像比率固定 2:1想定
		position: relative;
		overflow: hidden;
		z-index: 1;

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
		}
	}

	&__content {
		padding: 0 16px 23px;
	}

	&__title {
		font-weight: bold;
		font-size: 1.8rem;
		line-height: var(--line-height-s);
	}

	&__text {
		margin-top: 8px;
		color: var(--color-txt);
	}

	&__tag {
		margin-top: 8px;
		border-color: #999;
		color: #999;
	}

	&__button {
		// max-width: 312px;
		margin: 16px auto 0;
	}

	@include m.mq-pc {
		a#{$_root}__inner {
			transition: box-shadow .2s, border-color .2s;

			&:hover {
				box-shadow: 0 0 15px 0 rgba(0, 0, 0, .2);
				border-color: transparent;

				#{$_root}__title {
					color: var(--color-txt-hover);
				}
			}

			#{$_root}__tag {
				padding-left: 0;
				padding-right: 0;
			}
		}

		&__title {
			transition: color .2s;
		}

		&__tag {
			padding-left: 10px;
			padding-right: 10px;
		}

		&__button {
			padding-left: 10px;
			padding-right: 10px;
		}
	}
}
