@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	input
----------*/
.c-input {
	> input[type='text'],
	> input[type='number'],
	> input[type='email'] {
		display: block;
		width: 100%;
		height: 50px;
		padding: 14px 15px;
		background-color: #fff;
		border: 1px solid var(--color-border);
		appearance: none;
		-webkit-appearance: none;

		&::placeholder {
			color: #888888;
		}

		&::-webkit-input-placeholder {
			color: #888888;
		}

		&:-moz-placeholder {
			color: #888888;
		}

		&::-moz-placeholder {
			color: #888888;
		}

		&:-ms-input-placeholder {
			color: #888888;
		}
	}

	@include m.mq-pc {
		&__item {
			&--300 {
				max-width: 300px;
			}

			&--340 {
				max-width: 340px;
			}

			&--400 {
				max-width: 400px;
			}

			&--420 {
				max-width: 420px;
			}

			&--460 {
				max-width: 460px;
			}
		}
	}

	&.is-error {
		> input[type='text'],
		> input[type='number'],
		> input[type='email'] {
			padding: 12px 20px;
			color: var(--color-caution);
			border-color: var(--color-caution);
			background: #FFF6F5;
		}
	}
}
