@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	anchor
----------*/
$_root: '.c-tab';

.c-tab {

	&__tab {

		display: flex;
		flex-wrap: wrap;
		gap: 1px;

		@include m.mq-pc {
			flex-wrap: nowrap;
			border-bottom: #194192 solid 2px;
		}

		&-item {
			background: var(--color-bg);
			color: var(--color-txt-caption);
			width: calc((100% - 1px) / 2);

			@include m.mq-pc {
				width: 200px;
				border-radius: 5px 5px 0 0;
				overflow: hidden;
			}

			&.is-active {
				background: #194192;
				color: #FFF;
				pointer-events: none;
				cursor: default;
			}
		}

		&-link {
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 1.6rem;
			line-height: var(--line-height-s);
			text-align: center;
			width: 100%;
			min-height: 40px;
			height: 100%;
			padding: 6px 10px;
			color: inherit;

			@include m.mq-pc {
				min-height: 60px;
				font-size: 1.8rem;
				padding: 10px 15px;
			}
		}

	}

	&__contents {
		&-item {
			display: none;

			&.is-active {
				display: block;
			}
		}
	}

}
