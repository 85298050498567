@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	imagelink-article
----------*/
$_root: '.c-imagelink-article';

.c-imagelink-article {
	&__inner {
		display: block;
		height: 100%;
		border-radius: 5px;
		position: relative;
		overflow: hidden;
		background-color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0,0,0,.05);
		cursor: pointer;
	}

	&__image {
		// 画像比率固定用の記述
		width: 100%;
		height: 0;
		padding-bottom: percentage( math.div(9, 16) ); // 画像比率固定 16:9想定
		position: relative;
		overflow: hidden;
		z-index: 1;

		> img {
			width: 100%;
			height: 100%;
			position: absolute;
			top: 0;
			left: 0;
			object-fit: cover;
			object-position: center;
			border-radius: 5px 5px 0 0;
		}
	}

	&__content {
		padding: 20px 20px 40px;
	}

	&__category {
		margin-bottom: 10px;
	}

	&__title {
		font-weight: bold;
		font-size: 1.6rem;
		color: var(--color-txt);
		line-height: var(--line-height-s);
	}

	&__text {
		margin-top: 20px;
		font-size: 1.4rem;
		color: var(--color-txt-caption);
		overflow: hidden;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 3;
	}

	&__icon {
		position: absolute;
		bottom: 16px;
		right: 16px;
		width: 16px;
		height: 16px;

		> i {
			font-size: 1.6rem;
		}
	}

	@include m.mq-pc {
		&__content {
			padding: 20px 30px 40px;
		}

		&__title {
			font-size: 1.8rem;
			transition: color .2s;
		}

		&__text {
			margin: 30px 0 2px;
			font-size: 1.5rem;
		}

		&__icon {
			bottom: 20px;
			right: 20px;
			width: 18px;
			height: 18px;

			> i {
				font-size: 1.8rem;
			}
		}

		@media (hover: hover) {
			&__inner {
				#{$_root}__image {
					> img {
						transition: transform 0.3s ease-out;
					}
				}
				#{$_root}__title {
					transition: color .2s;
				}

				&:hover {
					#{$_root}__image {
						> img {
							transform: scale(1.05);
						}
					}
					#{$_root}__title {
						color: var(--color-txt-caption);
					}
				}
			}
		}
	}
}
