@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	anchor
----------*/
$_root: '.c-anchor';

.c-anchor {

	@include m.mq-pc {
		display: flex;
		flex-wrap: wrap;
		gap: 10px 0;
	}

	&__item {
		border-top: 1px solid var(--color-border);
		border-bottom: 1px solid var(--color-border);
		box-sizing: border-box;
		margin-top: -1px;
	}

	&__link {
		display: block;
		height: 100%;
		padding: 19px 48px 20px 0;
		position: relative;

		@include m.mq-pc {
			padding: 18px 66px 18px 0;

			&:hover {
				#{$_root}__text {
					color: var(--color-prim);
				}

				#{$_root}__icon {
					color: var(--color-prim);
				}
			}
		}
	}

	&__text {
		color: var(--color-txt);
		font-size: 1.4rem;
		font-weight: 500;
		line-height: var(--line-height-s);
		transition: color .2s;

		@include m.mq-pc {
			font-size: 1.6rem;
		}
	}

	&__icon {
		position: absolute;
		right: 13px;
		top: 0;
		bottom: 0;
		margin: auto;
		font-size: 1.6rem;
		color: var(--color-prim);
		display: flex;
		align-items: center;
		transition: color .2s;

		@include m.mq-pc {
			font-size: 1.8rem;
		}
	}

}
