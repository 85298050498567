@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	separator
----------*/

.c-separator {
	display: block;
	border-top: 1px solid var(--color-border);

	&--dashed {
		border-top-style: dashed;
	}
}
