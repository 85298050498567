@use 'sass:math';
@use '../../../_mixin' as m;
/*----------
	logo
----------*/
.c-logo {
	width: 120px;

	> img {
		display: block;
		width: auto;
		max-width: 100%;
		height: auto;
	}
}
