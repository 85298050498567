@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	content box
----------*/

.c-contentbox {
	padding: 20px;
	background-color: var(--color-bg);

	&--colored {
		color: #E32653;
		background-color: #FFF7F7;
	}

	@include m.mq-pc {
		padding-left: 24px;
		padding-right: 24px;
	}
}

