@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	sublead
----------*/

.c-sublead {
	font-size: 1.6rem;
	line-height: var(--line-height-l);

	@include m.mq-pc {
		font-size: 2.1rem;
	}
}
