@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	lead
----------*/

.c-lead {
	font-size: 1.8rem;
	font-weight: 500;
	line-height: var(--line-height-l);

	@include m.mq-pc {
		font-size: 2.6rem;
	}
}
