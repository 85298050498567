@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-panel
----------*/

.c-panel {

	border: 8px solid var(--color-bg);
	padding: 30px 20px;

	&__title {
		font-size: 2rem;
		font-weight: bold;
	}

	&__body {
		margin-top: 5px;

		&:only-child {
			margin-top: 0;
		}
	}

	&__text {
		margin-top: 15px;
		color: var(--color-txt-caption-v2);
	}

	@include m.mq-pc {
		padding: 30px 22px;

		&__title {
			font-size: 2rem;
		}

		&__body {
			margin-top: 15px;
		}

		&__text {
			margin-top: 20px;
		}
	}
}
