@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	text
----------*/

.c-text {
	font-size: 1.4rem;
	line-height: var(--line-height-l);

	> a {
		text-decoration: underline;
	}

	&--bold {
		font-weight: bold;
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		> a {
			transition: all .2s;

			&:hover {
				text-decoration: none;
			}
		}
	}
}
