@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	input v2
----------*/
.c-input-v2 {
	> input[type='text'],
	> input[type='number'],
	> input[type='email'] {
		display: block;
		width: 100%;
		height: 50px;
		padding: 14px 15px;
		background-color: #fff;
		border: 1px solid var(--color-border);
		appearance: none;
		-webkit-appearance: none;

		&::placeholder {
			color: #888888;
		}

		&::-webkit-input-placeholder {
			color: #888888;
		}

		&:-moz-placeholder {
			color: #888888;
		}

		&::-moz-placeholder {
			color: #888888;
		}

		&:-ms-input-placeholder {
			color: #888888;
		}
	}

	&.is-error {
		> input[type='text'],
		> input[type='number'],
		> input[type='email'] {
			color: var(--color-caution);
			border-color: var(--color-caution);
			background: #FFF6F5;
		}
	}

	@include m.mq-pc {
		> input[type='text'],
		> input[type='number'],
		> input[type='email'] {
			padding: 10px 20px;
		}
	}
}
