@use 'sass:math';
@use '../../../_mixin' as m;

/*----------
	icons
----------*/
// .icon- 各クラスとフォントコードの紐付けは _mixin で定義
@font-face {
	font-family: iconfont;
	src: url(/assets/fonts/iconfont.woff2) format('woff2'), url(/assets/fonts/iconfont.woff) format('woff'), url(/assets/fonts/iconfont.svg) format('svg');
	font-weight: normal;
	font-style: normal;
	font-display: block;
}

.icon {
	display: inline-block;
	font-family: iconfont !important; /* stylelint-disable-line declaration-no-important,font-family-no-missing-generic-family-keyword */
	speak: never;
	font-style: normal !important; /* stylelint-disable-line declaration-no-important */
	font-size: inherit;
	font-weight: normal !important; /* stylelint-disable-line declaration-no-important */
	font-variant: normal !important; /* stylelint-disable-line declaration-no-important */
	text-transform: none;
	line-height: 1;
	color: inherit;

	@each $name, $glyph in m.$icons {
		&-#{$name} {
			@extend .icon;

			&::before {
				content: $glyph;
			}
		}
	}
}

.icon-pdf {
	color: var(--color-caution);
}

.icon-freedial {
	display: inline-block;
	vertical-align: baseline;
	line-height: 1;
	width: 70px;
}

.icon-phone {
	display: inline-block;
	vertical-align: baseline;
	line-height: 1;
	width: 60px;
}


.icon-sns {
	display: inline-block;
	vertical-align: baseline;
	line-height: 1;
	width: 40px;
}
