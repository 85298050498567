@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	radio
----------*/

.c-radio {
	/* stylelint-disable no-descending-specificity */
	cursor: pointer;

	input[type='radio'] {
		position: absolute;
		clip: rect(0 0 0 0);
		width: 1px;
		height: 1px;
		margin: -1px;
		padding: 0;
		overflow: hidden;
		border: 0;

		&:checked + span {
			color: var(--color-txt);

			&::before {
				background-image: url(/assets/images/marker_radio_on.svg);
			}
		}
	}

	span {
		position: relative;
		padding-left: 32px;
		display: inline-block;
		line-height: 2.4rem;
		text-align: left;
		// display: flex;
		// align-items: center;

		&::before {
			content: '';
			position: absolute;
			top: 0;
			left: 0;
			display: block;
			width: 24px;
			height: 24px;
			margin-right: 8px;
			background: url(/assets/images/marker_radio.svg) no-repeat center;
			background-color: #fff;
			background-size: contain;
			border-radius: 50%;
			overflow: hidden;
		}

		a {
			text-decoration: underline;
		}
	}

	&.is-error {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_radio_error.svg);
			}
		}
	}

	+ .c-input {
		display: inline-block;
		width: 100%;
		max-width: 440px;
		margin-top: 15px;

		@include m.mq-pc {
			margin-top: 0;
			margin-left: 22px;
		}
	}


	@at-root .is-error & {
		color: var(--color-caution);

		span {
			&::before {
				margin-right: 8px;
				background-image: url(/assets/images/marker_radio_error.svg);
			}
		}
	}
	/* stylelint-enable no-descending-specificity */
}
