@use 'sass:math';
@use '../../_mixin' as m;

/*----------
	anchor
----------*/
$_root: '.c-anchor';

.g-anchor-container {
	margin: 0 auto;

	// @include m.mq-sp {
	// 	&__item + &__item {
	// 		margin-top: 20px;
	// 	}
	// }

	@include m.mq-pc {
		width: 50%;

		// &__item {
		// 	+ .g-grid__item {
		// 		margin-top: 40px;
		// 	}
		// }

		@supports (display: grid) {
			display: grid;
			gap: 0 50px;
			width: auto;

			&--2up {
				grid-template-columns: repeat(2, 1fr);
			}

			&--3up {
				grid-template-columns: repeat(3, 1fr);
			}

			&--4up {
				grid-template-columns: repeat(4, 1fr);
			}
		}
	}
}
