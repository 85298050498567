@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	select
----------*/
.c-select-v2 {
	position: relative;
	cursor: pointer;

	&::after {
		@include m.iconfont-default;
		content: var(--icon-arrow3-b);
		font-size: 1.2rem;
		position: absolute;
		top: 19px;
		right: 16px;
		pointer-events: none;
	}

	> select {
		display: block;
		width: 100%;
		height: 50px;
		padding: 10px 50px 10px 20px;
		border: 1px solid var(--color-border);
		font-family: var(--font-sans);
		font-size: 1.6rem;
		font-weight: 500;
		line-height: var(--line-height-s);
		color: var(--color-txt);
		background-color: #fff;
		appearance: none;
		-webkit-appearance: none;

		&::-ms-expand {
			display: none;
		}
	}

	&.is-error {
		&::after {
			color: var(--color-caution);
		}

		> select {
			color: var(--color-caution);
			border-color: var(--color-caution);
			background: #FFF6F5;
		}
	}

	@include m.mq-pc {
		> select {
			padding-left: 20px;
		}
	}

	// &:not(.is-selected):not(.parsley-success) {
	// 	> select {
	// 		color: #CDD6DD;
	// 	}
	// }
}
