@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	c-txtpanel
----------*/
$_root: '.c-txtpanel';

.c-txtpanel {
	&__inner {
		display: block;
	}

	&__content {
		display: flex;
		align-items: center;
		min-height: 80px;
		background-color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);
		padding: 28px 16px 29px 20px;
		border-radius: 3px;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: var(--line-height-s);
		color: var(--color-txt);
	}

	&__text {
		font-size: 1.4rem;
		line-height: var(--line-height-l);
		color: var(--color-txt);
		margin-top: 15px;
		margin-left: 20px;
	}

	@include m.mq-pc {
		&__content {
			min-height: 100px;
			padding: 22px 22px 22px 30px;
		}

		&__title {
			font-size: 1.8rem;
			transition: color .2s;
		}

		&__text {
			font-size: 1.6rem;
			margin-top: 20px;
		}
	}
}
