@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	textpanel
----------*/
$_root: '.c-txtpanel-link';

.c-txtpanel-link {
	&__inner {
		display: block;
	}

	&__content {
		display: flex;
		align-items: center;
		min-height: 80px;
		background-color: #fff;
		box-shadow: 0 2px 10px 0 rgba(0, 0, 0, .05);
		padding: 28px 16px 29px 20px;
		border-radius: 3px;
	}

	&__title {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		font-size: 1.6rem;
		font-weight: bold;
		line-height: var(--line-height-s);
		color: var(--color-txt);

		&-icon {
			font-size: 1.6rem;
			color: var(--color-prim);
			text-indent: 0;
			margin-left: auto;
		}
	}

	&__text {
		font-size: 1.4rem;
		line-height: var(--line-height-l);
		color: var(--color-txt);
		margin-top: 15px;
		margin-left: 20px;
	}

	&__tag {
		display: inline-block;
		vertical-align: middle;
		margin-left: 10px;

		> a,
		> span {
			display: inline-block;
			min-width: 88px;
			height: 32px;
			padding: 0 11px;
			background-color: var(--color-black);
			border: 1px solid transparent;
			border-radius: 5px;
			font-weight: bold;
			font-size: 1.3rem;
			text-align: center;
			white-space: nowrap;
			line-height: 30px;
			color: #fff;
		}

		> a {
			color: var(--color-txt-link);
		}
	}

	@include m.mq-pc {
		&__content {
			min-height: 100px;
			padding: 22px 22px 22px 30px;

			&:hover {
				#{$_root}__title {
					color: var(--color-txt-hover);
				}
			}
		}

		&__title {
			font-size: 1.8rem;
			transition: color .2s;
		}

		&__text {
			font-size: 1.6rem;
			margin-top: 20px;
		}

		&__tag {
			margin-left: 20px;

			> a {
				transition: background-color .2s, color .2s;

				&:hover {
					background-color: var(--color-prim);
					color: #fff;
				}
			}
		}
	}
}
