@use 'sass:math';
@use '../../_mixin' as m;
/*----------
	WYSIWYG
----------*/
$_root: '.c-wysiwyg';

// 基本スタイル
.c-wysiwyg {
	@include m.margin-trim;
	font-size: 1.4rem;
	line-height: var(--line-height-l);

	a:not([class^='c-']) {
		text-decoration: underline;
		color: var(--color-txt-link);
	}

	strong,
	em {
		font-weight: bold;
	}

	img {
		width: auto;
		height: auto;
		max-width: 100%;
	}

	@include m.mq-pc {
		font-size: 1.6rem;

		a {
			transition: color 0.2s linear;

			&:hover {
				text-decoration: none;
			}
		}
	}
}

// p
#{$_root} > p {
	margin-top: 20px;
	font-size: 1.4rem;
	line-height: var(--line-height-l);

	@include m.mq-pc {
		font-size: 1.6rem;
	}
}

// h2
#{$_root} > h2 {
	margin-top: 80px;
	margin-bottom: 40px;
	position: relative;
	font-weight: 500;
	font-size: 2rem;
	line-height: var(--line-height-m);

	&::before {
		content: '';
		display: block;
		width: 40px;
		height: 3px;
		background: var(--color-prim);
		margin-bottom: 13px;
	}

	&::after {
		content: '';
		display: block;
		width: calc(100% - 50px);
		height: 1px;
		background: var(--color-border);
		position: absolute;
		top: 1px;
		right: 0;
	}

	@include m.mq-pc {
		margin-top: 120px;
		margin-bottom: 50px;
		font-size: 2.8rem;

		&::before {
			width: 80px;
		}

		&::after {
			width: calc(100% - 90px);
		}
	}
}

// h3
#{$_root} > h3 {
	margin-top: 60px;
	margin-bottom: 50px;
	padding: 15px 19px 16px 18px;
	position: relative;
	background-color: var(--color-heading-thin);
	font-weight: 500;
	font-size: 1.6rem;
	line-height: var(--line-height-s);

	&::before {
		content: '';
		width: 3px;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
		background-color: var(--color-prim);
	}

	@include m.mq-pc {
		margin-top: 80px;
		padding: 17px 30px 19px 27px;
		font-size: 2.4rem;

		&::before {
			width: 2px;
		}
	}
}

// h4
#{$_root} > h4 {
	margin-top: 40px;
	margin-bottom: 20px;
	font-weight: bold;
	font-size: 1.6rem;
	line-height: var(--line-height-s);

	@include m.mq-pc {
		margin-top: 50px;
		margin-bottom: 30px;
		font-size: 2rem;
	}
}

// ul
#{$_root} > ul {
	margin-top: 20px;

	> li {
		padding-left: 1em;
		text-indent: -1em;

		&::before {
			content: '';
			display: inline-block;
			width: .3em;
			height: .3em;
			margin-right: .75em;
			border-radius: 50%;
			vertical-align: middle;
			transform: translate(50%, -50%);
			background-color: var(--color-txt);
		}

		+ li {
			margin-top: 10px;
		}
	}
}

// ol
#{$_root} > ol {
	margin-top: 20px;
	counter-reset: orderlist;

	> li {
		padding-left: 1.5em;
		text-indent: -2.5em;

		&::before {
			counter-increment: orderlist;
			content: counter(orderlist)'.';
			display: inline-block;
			margin-right: .5em;
			min-width: 2em;
			text-align: right;
		}

		+ li {
			margin-top: 10px;
		}
	}
}

// table
#{$_root} > table {
	margin-top: 20px;
	width: 100%!important; /* stylelint-disable-line declaration-no-important */
	text-align: left;
	border: none;
	font-size: 1.6rem;
	line-height: var(--line-height-m);

	td,
	th {
		border: 1px solid var(--color-border);
		vertical-align: middle;
	}

	td {
		padding: 28px 24px;
	}

	th {
		padding: 28px 10px;
		background-color: var(--color-heading-dark);
		font-weight: 500;
		text-align: center;
		color: #fff;
	}
}

// wysiwyg-table
.c-wysiwyg-table {
	margin-top: 20px;

	table {
		width: 100%!important; /* stylelint-disable-line declaration-no-important */
		text-align: left;
		border: none;
		font-size: 1.6rem;
		line-height: var(--line-height-m);

		td,
		th {
			border: 1px solid var(--color-border);
			vertical-align: middle;
		}

		td {
			padding: 28px 24px;
		}

		th {
			padding: 28px 10px;
			background-color: var(--color-heading-dark);
			font-weight: 500;
			text-align: center;
			color: #fff;
		}
	}

	@include m.mq-sp {
		&--scrollable {
			$gutter: math.div(20, 375);

			&::after {
				content: '';
				display: block;
				margin-top: 12px;
				padding-top: 32px;
				background: url(/assets/images/table_swipe.svg) no-repeat center;
				background-size: contain;
			}

			.c-wysiwyg-table__inner {
				margin-left: percentage( $gutter * -1 );
				margin-right: percentage( $gutter * -1 );
				padding-left: percentage( $gutter );
				overflow: auto;
			}

			.c-wysiwyg-table__body {
				width: 1000px;
				padding-bottom: 10px;
				padding-right: percentage( $gutter );
			}
		}
	}
}
